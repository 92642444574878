import React from "react";
import Link from "next/link";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from "@material-ui/core/IconButton";
import Logo from "./Logo";
import UserMenu from "./UserMenu";
import SiteMenu from "./SiteMenu";
import SocialMenu from "./SocialMenu";
import MobileMenu from "./MobileMenu";

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  background: transparent;
  position: fixed;
  // border-bottom: 1px solid #000;
  // box-shadow: rgba(0,0,0,0.05) 0px 1px 2px 0px;
`

const StyledToolbar = styled.div`
  height: 8rem;
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%,  rgba(32,32,32,0.4) 30%, rgba(32,32,32,1) 100%);
  display:flex;
  position:relative;
  justify-content:center;
`;

const Container = styled(Toolbar)`
  position:relative;
  display:flex;
  justify-content:space-between;
  width: 1350px;
`

const MobileMenuButton = styled(IconButton)`
  margin-right: 0;

  @media (min-width: 800px) {
    display: none;
  }
`

const LogoContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  margin-right: 40px;
`

const Header = () => {
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);

  return (
    <>
      <MobileMenu open={mobileOpen} onClose={() => setMobileOpen(false)} />

      <StyledAppBar position="static">
        <StyledToolbar>
          <Container>
            <Box display="flex" alignItems="center" flex="1">

              <MobileMenuButton edge="start" aria-label="menu" onClick={() => setMobileOpen(true)}>
                <MenuIcon />
              </MobileMenuButton>

              <Link href="/" passHref>
                <LogoContainer>
                  <Logo />
                </LogoContainer>
              </Link>

              <SiteMenu />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {/* <SocialMenu /> */}
              <UserMenu />
            </Box>
          </Container>
        </StyledToolbar>
      </StyledAppBar>
  
    </>
  )
}

export default Header;
