import React from "react";
import Link from "next/link";
import styled from "styled-components";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MapIcon from '@material-ui/icons/Map';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SubjectIcon from '@material-ui/icons/Subject';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PaymentIcon from '@material-ui/icons/Payment';
import useUser from "hooks/useUser";


const NextLink = styled(Link)`
    color: inherit;
    text-decoration: inherit;
`

const Container = styled.div`
  width: 240px;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  
  padding-top: 12vh;
 
`

const Menu = () => {
  const user = useUser();

  return (
    <Container>
      <List>
        <NextLink className="unlink" href="/admin/" passHref>
          <ListItem button>
            <ListItemIcon><MapIcon /></ListItemIcon>
            <ListItemText primary="Mapa" />
          </ListItem>
        </NextLink>
        <NextLink href="/admin/players" passHref>
          <ListItem button>
            <ListItemIcon><EmojiPeopleIcon /></ListItemIcon>
            <ListItemText primary="Players" />
          </ListItem>
        </NextLink>
      </List>
      <Divider />
      <List>
        <NextLink href="/admin/whitelists" passHref>
          <ListItem button>
            <ListItemIcon><SubjectIcon /></ListItemIcon>
            <ListItemText primary="Whitelists" />
          </ListItem>
        </NextLink>
        {user?.roles.includes('Admin') && (
          <>
            <NextLink href="/admin/payments" passHref>
              <ListItem button>
                <ListItemIcon><PaymentIcon /></ListItemIcon>
                <ListItemText primary="Pagamentos" />
              </ListItem>
            </NextLink>
            <NextLink href="/admin/coupons" passHref>
              <ListItem button>
                <ListItemIcon><LocalOfferIcon /></ListItemIcon>
                <ListItemText primary="Cupons" />
              </ListItem>
            </NextLink>
          </>
        )}
      </List>
    </Container>
  )
}

export default Menu;
