import React from "react";
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Button, { ButtonProps } from "@material-ui/core/Button";
import styled from "styled-components";

const StyledButton = styled(Button)`
  margin-left: 10px;
  font-size: 14px;
  font-family: "Lino";
  background: rgb(30, 30, 30, 0.5);
  padding-inline: 20px;
  padding-block: 10px;
`;

const {
  publicRuntimeConfig: { discordClientId },
} = getConfig();

const Login = (props: ButtonProps & { text?: string }) => {
  const baseUri = window.location.protocol + '//' + window.location.host;
  const redirectUri = `${baseUri}/api/discord/authorize`;
  const scopes = ['connections', 'guilds', 'identify', 'email'];
  const loginUri = `https://discord.com/api/oauth2/authorize?client_id=${discordClientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes.join(' ')}`;

  return (
    <StyledButton href={loginUri} {...props}>
      {props.text || 'Juntar-se a Comunidade'}
    </StyledButton>
  )
}

export default dynamic(() => Promise.resolve(Login), {
  ssr: false
})
