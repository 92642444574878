import React from "react";
import Link from "next/link";
import styled from "styled-components";
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Logo from "./Logo";

const LogoContainer = styled.div`
  width: 240px;
  text-align: center;
  padding: 30px 50px 10px;
  font-size: 14px;

  img {
    width: 100%;
  }
`

type Props = {
  open: boolean;
  onClose: () => void;
}

const MobileMenu = ({ open, onClose }: Props) => {
  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <List component="nav">
{/* 
        <ListItem button component="a" href="/allowlist" onClick={onClose}>
          <ListItemText primary="Allowlist" />
        </ListItem>

        <ListItem button component="a"  href="/loja" onClick={onClose}>
          <ListItemText primary="Loja" />
        </ListItem> */}

        <ListItem button component="a" href="https://faroesterp.com/discord">
          <ListItemText primary="Discord" />
        </ListItem>

        <ListItem button component="a" href="https://forum.nxtstudio.net/t%C3%B3pico/87/regras-gerais/">
          <ListItemText primary="Regras" />
        </ListItem>

      </List>
      <Divider />
      <List>
        <ListItem button component="a" href="https://www.instagram.com/faroesterp">
          <ListItemIcon><InstagramIcon /></ListItemIcon>
          <ListItemText primary="Instagram" />
        </ListItem>
        <ListItem button component="a" href="https://twitter.com/faroesterp">
          <ListItemIcon><TwitterIcon /></ListItemIcon>
          <ListItemText primary="Twitter" />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default MobileMenu;
