import { useEffect } from 'react';
import Router from 'next/router';
import useSWR from 'swr';

type User = {
  logged: boolean;
  name: string;
  discriminator: string;
  avatar: string;
  roles: string[];
  isAdmin: boolean;
  isIncomplete: boolean;
  cash: number;
}

export default function useUser(loggedOnly: boolean = false, adminOnly: boolean = false): User | undefined {
  const { data: user } = useSWR('/api/user');

  useEffect(() => {
    if (!user) return;

    if (loggedOnly && !user.logged) Router.replace('/');
    if (adminOnly && !user.isAdmin) Router.replace('/'); 
    if (user.isIncomplete) Router.replace('/account/create'); 
  }, [user]);

  return user;
}
