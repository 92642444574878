import React from "react";
import useUser from "hooks/useUser";
import { useRouter } from 'next/router';

import styled from "styled-components";
import AdminMenu from "./AdminMenu";

import Header from "./Header";
import Footer from "./Footer";

import { DefaultBackground } from "./Background";

const Container = styled.div`
  display: flex;
`

type Layout = {
    children: React.ReactNode
}

const Layout: React.FC<Layout> = ({ children }) => {
  const user = useUser();
  const router = useRouter();

  return (
    <>
      <Header />

      <Container>
        {(router.pathname.startsWith('/admin') && user?.isAdmin) && <AdminMenu />}
        <DefaultBackground />
        {children}
      </Container>
      
      <Footer />
    </>
  )
}

export default Layout;
