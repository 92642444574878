import React from "react";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

const FooterContent = styled(Box)`
  position:relative;
  display:flex;
  justify-content:space-between;
  background: rgba(20, 20, 20, 0.9);
  width: 100vw;
  min-height: 200px;
  padding-inline: 2rem;
  padding-block: 3rem;

  @media (max-width: 800px) {
    padding-inline: 1rem;
    padding-block: 1rem;
  }

`

const Cont = styled(Container)`
  position:relative;
  display:flex;
  justify-content:space-between;
  width: 1350px;
  @media (max-width: 800px) {
    width: 100%;
  }
`

const CBox = styled(Box)`
  display:flex;
  align-items: center;
  flex: 1;
  gap: 2rem;
  justify-content: space-around;
  
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const BoxItem = styled.div`
  width: 400px;
  height: 450px;
  @media (max-width: 800px) {
    width: 100%;
    height: 450px;
  }
`
const Footer = () => {
    

  return (
    <FooterContent>
        <Cont>
            <CBox>
              <BoxItem>
                <iframe 
                  src="https://discord.com/widget?id=412627639380213760&amp;theme=dark" 
                  title="discord" 
                  width="100%" 
                  height="100%" 
                  frameBorder="0" 
                  sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
                />
              </BoxItem>

              <BoxItem>
                <iframe 
                  id="twitter-widget-0"
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen
                  allowTransparency
                  width="100%" 
                  height="100%" 
                  title="Twitter Timeline"
                  src="https://syndication.twitter.com/srv/timeline-profile/screen-name/FaroesteRP?dnt=false&amp;embedId=twitter-widget-0&amp;features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&amp;frame=false&amp;hideBorder=false&amp;hideFooter=false&amp;hideHeader=false&amp;hideScrollBar=false&amp;lang=en&amp;maxHeight=495px&amp;origin=https%3A%2F%2Fwww.faroesterp.com%2F&amp;sessionId=da189112c1281a31fc7c3471f9d8e1f35b01f0c5&amp;showHeader=true&amp;showReplies=false&amp;theme=dark&amp;transparent=false&amp;widgetsVersion=2615f7e52b7e0%3A1702314776716"
                  style={{
                    position: "static",
                    visibility: "visible",
                    display: "block",
                    flexGrow: "1"
                  }}
                />
              </BoxItem>
            </CBox>
        </Cont>
    </FooterContent>
  )
}

export default Footer;
